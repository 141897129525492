<template>
  <div>
    <!-- Page Header -->
    <base-page-header title="Training Videos" backLink="resource_center">
      <template #buttons v-if="isConfigure">
        <div class="d-flex justify-content-end">
          <router-link :to="{ name: 'training_videos_config' }">
            <button class="btn btn-outline-dark">Configurate</button>
          </router-link>
        </div>
      </template>
    </base-page-header>

    <!-- Page Content -->
    <base-page-content>
      <template #content>
        <page-spinner v-if="ui.loading" />
        <div class="row" v-else>
          <div class="col-3">
            <training-videos-list :video-list="videos" @select-video="selectVideo" />
          </div>
          <div class="col-9">
            <training-selected-video :selected-video="selectedVideo" />
          </div>
        </div>
      </template>
    </base-page-content>
  </div>
</template>

<script>
import BasePageContent from "../../../components/BasePageContent.vue";
import BasePageHeader from "../../../components/BasePageHeader.vue";

export default {
  components: {
    BasePageHeader,
    BasePageContent,
    TrainingVideosList: () => import("./includes/TrainingVideosList.vue"),
    TrainingSelectedVideo: () => import("./includes/TrainingSelectedVideo.vue"),
    PageSpinner: () => import("../../../components/pageSpinner.vue"),
  },
  name: "TrainingVideosSingle",
  data() {
    return {
      selectedVideo: {},
      videos: [],
      ui: {
        loading: false,
      },
      isConfigure: false
    };
  },
  beforeMount() {
    this.getVideos();
  },
  methods: {
    getVideos() {
      this.ui.loading = true;

      this.$http
        .get("/api/v1/training-videos?query=&offset=0&limit=20")
        .then((res) => {
          this.videos = res.data.data;
          this.isConfigure = res.data.security.configuration;
          this.ui.loading = false;
        });
    },
    selectVideo(video) {
        this.selectedVideo = video;
    }
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  padding-top: 12px;
}
.page-content {
  padding-top: 20px;
}
</style>
